import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Background from "../images/Bg6.png"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

class ServicePageWebMaintenanceTemplate extends React.Component {
    render() {
        const webmaintenancepage = this.props.data.contentfulWebmaintenance
        let title = webmaintenancepage.cityName + " Web Maintenance | Expert Website Maintenance"
        let keywords = [webmaintenancepage.cityName + " Website Maintenance", webmaintenancepage.cityName + " Website Content Update", webmaintenancepage.cityName + " Website Help", webmaintenancepage.cityName + " Website Redesign",]
        let location = "/" + webmaintenancepage.slug
        let description = "Pyxel Development offers professional " + webmaintenancepage.cityName + " Website Maintenance, " + webmaintenancepage.cityName + " Website Content Update, " + webmaintenancepage.cityName + " Website Help, and " + webmaintenancepage.cityName + " Website Redesign services that are guaranteed to boost your business to the next level at an affordable rate."
        let isProduct = true
        let productName = webmaintenancepage.cityName + " Website Maintenance"
        let sku = "10000006"
        return (
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} isProduct={isProduct} productName={productName} sku={sku} />
                <div className="bg-no-repeat bg-cover bg-left"
                    style={{
                        backgroundImage: `url(${Background})`,
                        width: '100%',
                        height: '100%',
                        backgroundAttachment: 'absolute',
                    }}>
                    <div className="container gap-x-10 grid lg:grid-cols-2 text-white font-bold p-10 pt-36 mx-auto xl:py-40">
                        <div className="flex justify-center lg:justify-end">
                            <div>
                                <p>SERVICE</p>
                                <h1 className="text-5xl md:text-6xl xl:text-7xl font-pixeboy" style={{ lineHeight: "80%" }}>
                                    {webmaintenancepage.cityName}<br />{webmaintenancepage.serviceType}
                                </h1>
                                <h2 className="uppercase text-md md:text-xl max-w-lg mt-7">
                                    UPDATE AND MAINTAIN YOUR WEBSITE TO STAY AHEAD OF YOUR COMPETITION
                                </h2>
                                <div className="max-w-xl font-normal text-xs md:text-base">
                                    <p className="mt-7 md:mt-12">
                                        We understand the limitations that some may experience with self-implemented website maintenance, which is why Pyxel Development is here to help. Our experienced
                                        team of developers can take the hassle of website maintenance and content updates off your hands, so you can focus on what you do best.<br /><br />
                                        Websites are an amazing collection of tools that are used to show off your work and promote your business. It is essential that website maintenance is performed on
                                        a regular basis to provide potential clients with the most relevant content.
                                    </p>
                                    <div className="hidden lg:block mt-10 md:mt-12 xl:mt-28">
                                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                            <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                                <span>Request Quote</span>
                                            </button>
                                        </Link>
                                        <div className="hover:text-gray-600 mt-4">
                                            <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development" >
                                                Call Us: (916) 821-9044
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center pt-10 lg:pt-0 mx-auto max-w-md lg:max-w-xl">
                            <StaticImage src="../images/website-construction.png" placeholder="none" title={webmaintenancepage.cityName + " web maintenance agency"} alt={webmaintenancepage.cityName + " web maintenance agency"} />
                        </div>
                        <div className="lg:hidden mx-auto mt-10 md:mt-12 xl:mt-28">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                    <span>Request Quote</span>
                                </button>
                            </Link>
                            <div className="hover:text-gray-600 mt-4">
                                <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                    Call Us: (916) 821-9044
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner">
                    <div className="py-10 md:py-24">
                        <div className="text-white px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase mx-auto text-2xl font-bold md:text-4xl">
                                    IMPORTANCE OF WEBSITE MAINTENANCE
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />Once you have your website, implementing regular website maintenance is a critical step in keeping your website running smoothly and bug-free. Like a car sitting in a garage,
                                    something is bound to go wrong on your website if it does not receive consistent updates and maintenance. Losing browser support to plugins, having outdated technologies, and outdated
                                    content are directly correlated to website maintenance and negatively affect website performance if not immediately addressed.<br /><br />
                                    Our {webmaintenancepage.cityName} website maintenance team can take the hassle of maintaining and updating your website’s content off your hands. Whether we built your website or you are coming with an existing
                                    site, we can help keep your website at its peak performance.
                                </p>
                            </div>
                        </div>
                        <div className="flex order-2 md:justify-end md:order-1 pt-7">
                            <StaticImage src="../images/website-maintenance.png" className="mx-auto max-w-xs" placeholder="none" title={webmaintenancepage.cityName + " Website Maintenance"} alt={webmaintenancepage.cityName + " Website Maintenance"} />
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="py-10 md:py-24">
                        <div className="px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase mx-auto text-2xl font-bold md:text-4xl">
                                    LET US MAINTAIN YOUR WEBSITE
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />We strive to create the highest quality websites for our clients and want to ensure you that your website will be responsive, dynamic, and blazing fast by implementing
                                    the latest technologies and practices in the web development industry.<br /><br />
                                    We take pride in our ability to increase efficiency and site performance by minimizing the need for outdated frameworks and methods and by incorporating the following technologies:
                                </p>
                            </div>
                        </div>
                        <div className="container mx-auto pt-5 px-10 lg:px-20 grid grid-cols-1 space-y-3 md:space-y-0 md:grid-cols-2 lg:grid-cols-7 text-center text-xs">
                            <div className="mx-auto max-w-xs md:pb-3">
                                <div className="pt-4 flex justify-center">
                                    <UpdatesIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">CONTENT UPDATES</h3>
                                <p>
                                    Add and update content on your website to maintain relevancy and
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs md:pb-3">
                                <div className="pt-4 lg:pt-32 flex justify-center">
                                    <BugIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">BUG FIXES</h3>
                                <p>
                                    Fix unexpected issues to ensure a smooth user experience
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs md:pb-3">
                                <div className="pt-4 flex justify-center">
                                    <ServerIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">WEB HOSTING</h3>
                                <p>
                                    Update your domain, SSL certificates, and hosting services
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs md:pb-3">
                                <div className="pt-4 lg:pt-32 flex justify-center">
                                    <HealthIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">HEALTH CHECKUPS</h3>
                                <p>
                                    Consistent checkups to guarantee peak performance
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs md:pb-3">
                                <div className="pt-4 flex justify-center">
                                    <SecurityIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">SECURITY UPDATES</h3>
                                <p>
                                    Ensure website safety and security to build customer confidence and trust
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs md:pb-3">
                                <div className="pt-4 lg:pt-32 flex justify-center">
                                    <TrafficGrowthIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">TRAFFIC GROWTH</h3>
                                <p>
                                    Engage with new and current viewers with consistent and fresh content
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <div className="pt-4 flex justify-center">
                                    <OptimizationIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">OPTIMIZATION</h3>
                                <p>
                                    Improve website performance, increase traffic and grow conversion rates
                                </p>
                            </div>
                            <div className="lg:hidden mx-auto max-w-xs">
                                <div className="pt-4 flex justify-center">
                                    <ErrorIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">ERROR FIXES</h3>
                                <p>
                                    Fix any unexpected errors that occur on your website before they cause more issues
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-offblue">
                    <div className="py-10 md:py-24">
                        <div className="px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase text-white mx-auto text-2xl font-bold md:text-4xl">
                                    WEB MAINTENANCE TIMELINE
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />As your website is running, there are numerous different tasks that need to be completed within a specific time period.
                                    Web maintenance is done periodically throughout the year and requires an in-depth task list to ensure peak performance.
                                    We keep track of all the details for you, to allow you to focus on building your brand!
                                </p>
                            </div>
                        </div>
                        <div className="container mx-auto pt-5 md:pt-10 px-10 lg:px-20 grid grid-cols-1 md:grid-cols-4 gap-x-10 text-center text-xs">
                            <div className="mx-auto max-w-xs">
                                <div className="pt-4 md:pt-0 flex justify-center">
                                    <CalendarIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">WEEKLY</h3>
                                <p>
                                    <span className="text-white">▻ </span>Inspect for 404 errors<br />
                                    <span className="text-white">▻ </span>Update plugins & software<br />
                                    <span className="text-white">▻ </span>Update blog posts<br />
                                    <span className="text-white">▻ </span>Ensure proper function of each page<br />
                                    <span className="text-white">▻ </span>Update content & information<br />
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <div className="pt-4 md:pt-0 flex justify-center">
                                    <CalendarIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">MONTHLY</h3>
                                <p>
                                    <span className="text-white">▻ </span>Inspect SEO & local search visibility<br />
                                    <span className="text-white">▻ </span>Analyze website statistics<br />
                                    <span className="text-white">▻ </span>Analyze for security breaches and website safety<br />
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <div className="pt-4 md:pt-0 flex justify-center">
                                    <CalendarIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">QUARTERLY</h3>
                                <p>
                                    <span className="text-white">▻ </span>Thorough review of entire website<br />
                                    <span className="text-white">▻ </span>Update content<br />
                                    <span className="text-white">▻ </span>Update all meta description and titles<br />
                                    <span className="text-white">▻ </span>Inspect SEO performance & make necessary improvements<br />
                                    <span className="text-white">▻ </span>Test for broken links<br />
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <div className="pt-4 md:pt-0 flex justify-center">
                                    <CalendarIcon />
                                </div>
                                <h3 className="pt-2 font-bold lg:text-sm">ANNUALLY</h3>
                                <p>
                                    <span className="text-white">▻ </span>Renew website domain & hosting service<br />
                                    <span className="text-white">▻ </span>Refresh website design to maintain relevancy<br />
                                    <span className="text-white">▻ </span>Thorough website review to increase performance<br />
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center pt-5 md:pt-10">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-white">
                                    <span>Get Started <b>TODAY!</b></span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default ServicePageWebMaintenanceTemplate
export const pageQuery = graphql`
query ContentfulWebmaintenanceBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulWebmaintenance( slug: {eq: $slug }) {
        title
        cityName
        boldName
        serviceType
        slug
    }
}
`
function UpdatesIcon() {
    return (
        <svg title="Content Updates" alt="Content Updates" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M14.408 24h-13.408v-24h10.189c3.163 0 9.811 7.223 9.811 9.614v2.068c-.622-.296-1.293-.502-2-.603v-.623c0-4.106-6-2.456-6-2.456s1.518-6-2.638-6h-7.362v20h9.26c.566.81 
        1.299 1.49 2.148 2zm2.257-8.669c.402-.206.852-.331 1.335-.331 1.455 0 2.67 1.042 2.941 2.418l1.96-.398c-.456-2.291-2.475-4.02-4.901-4.02-.957 0-1.845.278-2.604.745l-1.396-1.745-1 5h5l-1.335-1.669zm5.335 8.669l-1.396-1.745c-.759.467-1.647.745-2.604.745-2.426 
        0-4.445-1.729-4.901-4.02l1.96-.398c.271 1.376 1.486 2.418 2.941 2.418.483 0 .933-.125 1.335-.331l-1.335-1.669h5l-1 5z"/></svg>
    )
}

function BugIcon() {
    return (
        <svg title="Bug Fixes" alt="Bug Fixes" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.074 1.408c0-.778.641-1.408 1.431-1.408.942 0 1.626.883 1.38 1.776-.092.336-.042.695.139.995.4.664 1.084 1.073 1.977 
        1.078.881-.004 1.572-.408 1.977-1.078.181-.299.23-.658.139-.995-.248-.892.434-1.776 1.378-1.776.79 0 1.431.63 1.431 1.408 0 .675-.482 1.234-1.117 1.375-.322.071-.6.269-.77.548-.613 1.017.193 1.917.931 
        2.823-1.211.562-2.525.846-3.97.846-1.468 0-2.771-.277-3.975-.84.748-.92 1.555-1.803.936-2.83-.17-.279-.447-.477-.77-.548-.634-.14-1.117-.699-1.117-1.374zm12.207 15.18c-.104.645.27 1.162.764 1.383l2.42 
        1.078c.531.236.766.851.526 1.373-.241.524-.866.752-1.395.518l-2.561-1.14c-.341-.152-.706-.062-.964.364-1.063 1.76-3.138 3.509-6.071 3.836-2.991-.333-5.091-2.146-6.135-3.943-.2-.342-.564-.409-.901-.259l-2.561 
        1.14c-.534.237-1.155.002-1.395-.518-.239-.522-.005-1.137.526-1.373l2.42-1.078c.523-.233.863-.734.751-1.462-.106-.685-.692-1.057-1.265-1.057h-2.385c-.583.002-1.055-.462-1.055-1.035s.472-1.037 1.055-1.037h2.402c.552 
        0 1.129-.353 1.234-1.111.082-.58-.277-1.082-.773-1.274l-2.23-.866c-.542-.21-.808-.813-.594-1.346.212-.53.822-.796 1.367-.584.231.09 2.332.944 2.572.944.242 0 .502-.125.663-.491.181-.408.384-.787.601-1.146 1.709.998 
        3.59 1.496 5.703 1.496 2.08 0 3.986-.51 5.699-1.502.225.372.436.765.62 1.188.14.319.394.454.648.454.39 0 2.462-1.016 2.958-1.016.42 0 .817.249.98.657.214.533-.053 1.135-.594 1.346l-2.23.866c-.479.186-.857.655-.766 
        1.333.09.659.652 1.052 1.227 1.052h2.402c.583 0 1.055.464 1.055 1.037s-.472 1.037-1.055 1.037h-2.387c-.557 0-1.146.332-1.276 1.136zm-8.281 5.167v-10.798c-1.356-.093-2.652-.368-3.872-.839-1.247 2.763-1.274 10.056 
        3.872 11.637zm5.859-11.642c-1.224.473-2.516.749-3.859.843v10.799c5.093-1.564 5.152-8.716 3.859-11.642z"/></svg>
    )
}

function ServerIcon() {
    return (
        <svg title="Web Hosting" alt="Web Hosting" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22 8v4h-20v-4h20zm2-2h-24v8h24v-8zm-21 5l.863-2h1.275l-.863 2h-1.275zm2.066 0l.864-2h1.275l-.863 2h-1.276zm2.067 0l.863-2h1.275l-.864 
        2h-1.274zm2.066 0l.863-2h1.274l-.863 2h-1.274zm3.341 0h-1.274l.863-2h1.275l-.864 2zm7.46 0c-.552 0-1-.448-1-1s.448-1 1-1c.553 0 1 .448 1 1s-.447 1-1 1zm2 7v4h-20v-4h20zm2-2h-24v8h24v-8zm-21 5l.863-2h1.275l-.863 2h-1.275zm2.066 
        0l.863-2h1.275l-.863 2h-1.275zm2.067 0l.863-2h1.275l-.864 2h-1.274zm2.066 0l.863-2h1.274l-.863 2h-1.274zm3.341 0h-1.274l.863-2h1.275l-.864 2zm7.46 0c-.552 0-1-.448-1-1s.448-1 1-1c.553 0 1 .448 1 1s-.447 1-1 1zm-15.597-16h-2.403l4-5h12l4 
        5h-2.403l-2.667-3h-9.86l-2.667 3z"/></svg>
    )
}

function HealthIcon() {
    return (
        <svg title="Health Checkups" alt="Health Checkups" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21.826 9h-2.086c.171-.487.262-.957.262-1.41 0-2.326-1.818-3.776-4.024-3.573-2.681.247-4.518 3.71-4.978 
        4.484-.527-.863-2.261-4.238-4.981-4.494-2.11-.199-4.019 1.181-4.019 3.582 0 3.109 4.347 7.084 9.001 11.615 1.16-1.127 2.285-2.208 3.324-3.243l.97 1.857c-1.318 1.302-2.769 2.686-4.294 4.181-6.164-6.037-11.001-10.202-11.001-14.403 
        0-3.294 2.462-5.526 5.674-5.596 2.163-.009 4.125.957 5.327 2.952 1.177-1.956 3.146-2.942 5.253-2.942 3.064 0 5.746 2.115 5.746 5.595 0 .464-.06.928-.174 1.395zm-11.094 4c-.346.598-.992 1-1.732 1-1.104 0-2-.896-2-2s.896-2 2-2c.74 0 
        1.386.402 1.732 1h1.222l1.88-2.71c.14-.202.376-.315.622-.299.245.016.464.161.576.38l2.27 4.437.813-1.45c.124-.221.357-.358.611-.358h5.274v2h-4.513l-1.759 2.908c-.132.219-.373.348-.629.337-.255-.01-.484-.16-.598-.389l-2.256-4.559-.989 
        1.406c-.131.186-.345.297-.573.297h-1.951z"/></svg>
    )
}

function SecurityIcon() {
    return (
        <svg title="Security Updates" alt="Security Updates" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 16c0-1.104.896-2 2-2s2 .896 2 2c0 .738-.404 1.376-1 1.723v2.277h-2v-2.277c-.596-.347-1-.985-1-1.723zm11-6v14h-18v-14h3v-4c0-3.313 2.687-6 
        6-6s6 2.687 6 6v4h3zm-13 0h8v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4zm11 2h-14v10h14v-10z"/></svg>
    )
}

function TrafficGrowthIcon() {
    return (
        <svg title="Traffic Growth" alt="Traffic Growth" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5 20v2h-2v-2h2zm2-2h-6v6h6v-6zm6-1v5h-2v-5h2zm2-2h-6v9h6v-9zm6-2v9h-2v-9h2zm2-2h-6v13h6v-13zm0-11l-6 1.221 1.716 1.708-6.85 6.733-3.001-3.002-7.841 
        7.797 1.41 1.418 6.427-6.39 2.991 2.993 8.28-8.137 1.667 1.66 1.201-6.001z"/></svg>
    )
}

function OptimizationIcon() {
    return (
        <svg title="Optimization" alt="Optimization" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.91 13.34l2.636-4.026-.454-.406-3.673 3.099c-.675-.138-1.402.068-1.894.618-.736.823-.665 2.088.159 2.824.824.736 
        2.088.665 2.824-.159.492-.55.615-1.295.402-1.95zm-3.91-10.646v-2.694h4v2.694c-1.439-.243-2.592-.238-4 0zm8.851 2.064l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.927-1.5-1.328zm-18.851 4.242h8v2h-8v-2zm-2 
        4h8v2h-8v-2zm3 4h7v2h-7v-2zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28c1.138.631 2.439 1 3.831 1 4.411 0 8-3.589 8-8s-3.589-8-8-8c-1.392 0-2.693.369-3.831 1h-3.28c1.811-1.845 4.321-3 7.111-3 5.523 0 
        10 4.477 10 10z"/></svg>
    )
}

function CalendarIcon() {
    return (
        <svg title="Calendar" alt="Calendar" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 
        .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm6.687 13.482c0-.802-.418-1.429-1.109-1.695.528-.264.836-.807.836-1.503 0-1.346-1.312-2.149-2.581-2.149-1.477 0-2.591.925-2.659 
        2.763h1.645c-.014-.761.271-1.315 1.025-1.315.449 0 .933.272.933.869 0 .754-.816.862-1.567.797v1.28c1.067 0 1.704.067 1.704.985 0 .724-.548 1.048-1.091 1.048-.822 0-1.159-.614-1.188-1.452h-1.634c-.032 1.892 1.114 2.89 2.842 2.89 1.543 0 2.844-.943 
        2.844-2.518zm4.313 2.518v-7.718h-1.392c-.173 1.154-.995 1.491-2.171 1.459v1.346h1.852v4.913h1.711z"/></svg>
    )
}

function ErrorIcon() {
    return (
        <svg title="Error" alt="Error" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M16.143 2l5.857 5.858v8.284l-5.857 5.858h-8.286l-5.857-5.858v-8.284l5.857-5.858h8.286zm.828-2h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-6.471 
        6h3l-1 8h-1l-1-8zm1.5 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>
    )
}